import { CompanyMembershipRole } from "@meterup/api/src/apiProtos";
import { EmailWithNameSchema, parseEmails } from "@meterup/ui/src/utils/parseEmails";
import cloneDeep from "lodash/cloneDeep";
import { z } from "zod";

export const InviteUsersSchema = z.object({
  users: z
    .object({
      parsed: z.array(EmailWithNameSchema),
      raw: z.string(),
    })
    .transform((val, ctx) => {
      const emailWithNames = parseEmails(val.raw);
      if (emailWithNames.length === 0) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: "No valid emails found." });
        return z.NEVER;
      }
      return {
        parsed: emailWithNames,
        raw: val.raw,
      };
    }),
  role: z.enum([
    CompanyMembershipRole.admin,
    CompanyMembershipRole.member,
    CompanyMembershipRole.unknown,
    CompanyMembershipRole.guest,
  ]),
  sendInvite: z.boolean(),
});

const defaultInviteUsers = {
  role: CompanyMembershipRole.member,
  sendInvite: false,
  users: {
    parsed: [],
    raw: "",
  },
};

export function getDefaultInviteUsers() {
  return cloneDeep(defaultInviteUsers);
}

export interface InviteUsersFormData extends z.TypeOf<typeof InviteUsersSchema> {}
