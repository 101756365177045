import { z } from "zod";

const EMAIL_RE = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const SEP = /[\s,]+/;

export const EmailWithNameSchema = z.object({
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});

export type EmailWithName = z.TypeOf<typeof EmailWithNameSchema>;

// Parses emails in GMail form of:
// First Last <first@last.com>
// or just a regular email address.
// Handles separator of whitespace character or comma.
export function parseEmails(input: string): EmailWithName[] {
  const emails: EmailWithName[] = [];
  const chars = input.split("");
  let accumulator: string[] = [];
  let emailStartIdx = -1;
  let lteStartIdx = -1;
  let lastWsIdx = -1;

  chars.forEach((char, i) => {
    if (char === ">" && emailStartIdx > -1) {
      const maybeEmail = input.substring(emailStartIdx, i).trim();
      if (EMAIL_RE.test(maybeEmail)) {
        const result: EmailWithName = { email: maybeEmail };
        const words = accumulator
          .join("")
          .substring(0, lteStartIdx)
          .split(/\s+/)
          .filter((word) => /\w/.test(word));
        const [firstWord] = words;
        if (words.length === 1) {
          result.firstName = firstWord;
        } else if (words.length > 1) {
          result.lastName = words.pop();
          result.firstName = words.join(" ");
        }
        emails.push(result);
      }
      emailStartIdx = -1;
      lteStartIdx = -1;
      accumulator = [];
      return;
    }

    if (char === "<") {
      emailStartIdx = i + 1;
      lteStartIdx = accumulator.length;
      return;
    }

    const lastChar = i + 1 === input.length;
    if (SEP.test(char) || lastChar) {
      const accumulatorJoined = accumulator.join("");
      let email = lastChar && EMAIL_RE.exec(accumulatorJoined + char)?.at(0);
      if (!email) {
        email = EMAIL_RE.exec(accumulatorJoined)?.at(0);
      }
      if (!email && lastWsIdx > -1) {
        email = EMAIL_RE.exec(input.substring(lastWsIdx + 1, i))?.at(0);
      }
      if (email) {
        accumulator = [];
        emailStartIdx = -1;
        emails.push({ email });
        return;
      }

      lastWsIdx = i;
    }

    accumulator.push(char);
  });

  return emails;
}
