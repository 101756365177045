import { CompanyMembershipRole, UserRole } from "./apiProtos";

export function companyMembershipRoleToUserRole(role: CompanyMembershipRole): UserRole {
  switch (role) {
    case CompanyMembershipRole.admin:
      return UserRole.UR_ADMIN;
    case CompanyMembershipRole.member:
      return UserRole.UR_MEMBER;
    default:
      return UserRole.UR_GUEST;
  }
}

export function userRoleToCompanyMembershipRole(role: UserRole): CompanyMembershipRole {
  switch (role) {
    case UserRole.UR_ADMIN:
      return CompanyMembershipRole.admin;
    case UserRole.UR_MEMBER:
      return CompanyMembershipRole.member;
    case UserRole.UR_GUEST:
      return CompanyMembershipRole.guest;
    default:
      return CompanyMembershipRole.unknown;
  }
}
