/* eslint-disable no-console */
import React, { useCallback, useMemo, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import type {
  AxiosAPIError,
  ConnectAdminCreateCompanyWithUsersRequest,
  ConnectAdminCreateCompanyWithUsersResponse,
} from "@meterup/api";
import { axios, CompanyMembershipRole } from "@meterup/api";
import { companyMembershipRoleToUserRole } from "@meterup/api/src/utils";
import {
  Alert,
  Badge,
  BasicSelect,
  BasicSelectItem,
  Body2,
  Button,
  CloseDrawerButton,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  FieldContainer,
  HStack,
  PrimaryField,
  Rule,
  SecondaryField,
  Text,
  Textarea,
  ToggleInput,
} from "@meterup/metric";
import { CloseDrawerLink } from "@meterup/ui";
import { UserListItem } from "@meterup/ui/src/components/UserListItem";
import useMetaSubmit from "@meterup/ui/src/hooks/useMetaSubmit";
import { SimpleList } from "@meterup/ui/src/styles/SimpleList";
import { pluralize } from "@meterup/ui/src/utils/string.ts/String.utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import take from "lodash/take";
import type { ControllerFieldState } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useCompanyOutletContext } from "../../../contexts/CompanyContext";
import type { InviteUsersFormData } from "../../../zod/inviteUsersSchema";
import { getDefaultInviteUsers, InviteUsersSchema } from "../../../zod/inviteUsersSchema";

function fieldStateHasError(fieldState: ControllerFieldState) {
  return !!(fieldState.isTouched && fieldState.error);
}

export default function CreateUser() {
  const [formData, setFormData] = useState<InviteUsersFormData>();
  const { companySID: slug } = useParams();
  const queryClient = useQueryClient();
  const onMetaSubmit = useMetaSubmit();
  const { company } = useCompanyOutletContext();
  const mutation = useMutation<
    ConnectAdminCreateCompanyWithUsersResponse,
    AxiosAPIError,
    ConnectAdminCreateCompanyWithUsersRequest
  >(
    (request) =>
      axios.post(`v1/connect/admin/companies/${encodeURIComponent(slug || "")}/users`, request),
    {
      async onSuccess() {
        await queryClient.invalidateQueries(["companyUsers", slug]);
      },
    },
  );
  const onBack = useCallback(
    (e) => {
      e.preventDefault();
      setFormData(undefined);
      mutation.reset();
    },
    [mutation],
  );
  const { control, handleSubmit } = useForm<InviteUsersFormData>({
    defaultValues: getDefaultInviteUsers(),
    // values: innerValues,
    resolver: zodResolver(InviteUsersSchema),
  });
  const onAdd = useCallback(
    (e) => {
      e.preventDefault();
      if (formData) {
        mutation.mutate({
          sendInvite: formData.sendInvite,
          users: {
            users: formData.users.parsed.map((user) => ({
              ...user,
              companyRole: formData.role,
              role: companyMembershipRoleToUserRole(formData.role),
            })),
          },
        });
      }
    },
    [formData, mutation],
  );
  const onSubmit = useMemo(() => handleSubmit(setFormData), [handleSubmit]);
  if (mutation.isSuccess && mutation.data?.company && mutation.data?.users) {
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Add company</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          <Alert
            icon="checkmarkCircle"
            variant="positive"
            heading={`${pluralize(mutation.data.users.results.length, "user")} invited to ${
              company?.name
            }`}
          />
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <CloseDrawerLink>
              <Button variant="secondary" type="button">
                Close
              </Button>
            </CloseDrawerLink>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  if (formData) {
    const errorData = mutation.error?.response?.data;
    return (
      <div>
        <DrawerHeader>
          <DrawerTitle>Invite users</DrawerTitle>
          <DrawerControls>
            <CloseDrawerLink>
              <CloseDrawerButton />
            </CloseDrawerLink>
          </DrawerControls>
        </DrawerHeader>
        <DrawerContent>
          {mutation.isError && errorData ? (
            <Alert
              icon="warning"
              variant="negative"
              heading="Failed to invite users"
              copy={
                <dl>
                  {take(Object.keys(errorData), 6).map((k) => (
                    <>
                      <dt>{k}</dt>
                      <dd>{errorData[k]}</dd>
                    </>
                  ))}
                </dl>
              }
            />
          ) : null}
          <Body2>
            The following email addresses will be invited to{" "}
            <Text weight="medium">{company?.name}</Text> with{" "}
            <Badge>
              <Text transform="capitalize">{formData.role}</Text>
            </Badge>{" "}
            permissions:
          </Body2>
          <SimpleList>
            {(formData?.users.parsed || []).map((emailWithName) => (
              <UserListItem key={`uli-${emailWithName.email}`} user={emailWithName} />
            ))}
          </SimpleList>
          <Rule height={2} />
          <HStack align="end" display="flex" spacing={8} justify="end">
            <Button variant="secondary" type="button" onClick={onBack}>
              Back
            </Button>
            <Button variant="primary" type="button" onClick={onAdd}>
              Add
            </Button>
          </HStack>
        </DrawerContent>
      </div>
    );
  }
  // @ts-ignore
  return (
    <form onSubmit={onSubmit} onKeyDown={onMetaSubmit(onSubmit)}>
      <DrawerHeader>
        <DrawerTitle>Invite users</DrawerTitle>
        <DrawerControls>
          <CloseDrawerLink>
            <CloseDrawerButton />
          </CloseDrawerLink>
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <FieldContainer>
          <Controller
            render={({ field, fieldState }) => (
              <PrimaryField
                label="Users"
                errorMessage={fieldState.error?.message}
                element={
                  <Textarea
                    placeholder="Email(s), comma or whitespace separated"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    // @ts-ignore
                    autoComplete="off"
                    hasError={fieldStateHasError(fieldState)}
                  />
                }
                description="Can use GMail contact format, e.g: First Last <first@last.com>"
              />
            )}
            name="users.raw"
            control={control}
          />
          <Controller
            render={({ field, fieldState }) => (
              <SecondaryField
                label="Role"
                errorMessage={fieldState.error?.message}
                element={
                  <BasicSelect
                    value={field.value}
                    id={field.name}
                    name={field.name}
                    onValueChange={field.onChange}
                    autoComplete="off"
                    hasError={fieldStateHasError(fieldState)}>
                    <BasicSelectItem value={CompanyMembershipRole.member}>Member</BasicSelectItem>
                    <BasicSelectItem value={CompanyMembershipRole.admin}>Admin</BasicSelectItem>
                  </BasicSelect>
                }
              />
            )}
            name="role"
            control={control}
          />
          <Controller
            render={({ field, fieldState }) => (
              <SecondaryField
                label="Send invite"
                errorMessage={fieldState.error?.message}
                element={
                  <div>
                    <ToggleInput
                      aria-label="Select whether to send an invite when creating the users in the company"
                      selected={field.value}
                      onChange={field.onChange}
                      defaultSelected
                    />
                  </div>
                }
              />
            )}
            name="sendInvite"
            control={control}
          />
        </FieldContainer>
        <Rule height={2} />
        <HStack align="end" display="flex" spacing={8} justify="end">
          <CloseDrawerLink>
            <Button variant="secondary" type="button">
              Cancel
            </Button>
          </CloseDrawerLink>
          <Button variant="primary" type="submit">
            Next
          </Button>
        </HStack>
      </DrawerContent>
    </form>
  );
}
